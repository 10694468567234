/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface UploadAsset$Params {

/**
 * Asset type
 */
  assetType: 'logo' | 'favicon';

/**
 * Id of the customer configuration
 */
  customerConfigurationId: string;
      body?: {

/**
 * File
 */
'file': Blob;
}
}

export function uploadAsset(http: HttpClient, rootUrl: string, params: UploadAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, uploadAsset.PATH, 'put');
  if (params) {
    rb.path('assetType', params.assetType, {});
    rb.path('customerConfigurationId', params.customerConfigurationId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

uploadAsset.PATH = '/assets/{assetType}/{customerConfigurationId}';
