/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { downloadAsset } from '../fn/assets/download-asset';
import { DownloadAsset$Params } from '../fn/assets/download-asset';
import { uploadAsset } from '../fn/assets/upload-asset';
import { UploadAsset$Params } from '../fn/assets/upload-asset';

@Injectable({ providedIn: 'root' })
export class AssetsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadAsset()` */
  static readonly UploadAssetPath = '/assets/{assetType}/{customerConfigurationId}';

  /**
   * Upload an asset of a customer.
   *
   * Allowed file types: image/x-icon, image/svg+xml, image/jpeg, image/png
   *          Allowed permissions: **custom/customerConfiguration/update**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAsset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadAsset$Response(params: UploadAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return uploadAsset(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload an asset of a customer.
   *
   * Allowed file types: image/x-icon, image/svg+xml, image/jpeg, image/png
   *          Allowed permissions: **custom/customerConfiguration/update**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAsset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadAsset(params: UploadAsset$Params, context?: HttpContext): Observable<void> {
    return this.uploadAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadAsset()` */
  static readonly DownloadAssetPath = '/assets/{assetType}/{customerConfigurationId}/download';

  /**
   * Download an asset of a customer.
   *
   * Allowed permissions: **No permission required**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAsset()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAsset$Response(params: DownloadAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadAsset(this.http, this.rootUrl, params, context);
  }

  /**
   * Download an asset of a customer.
   *
   * Allowed permissions: **No permission required**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAsset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAsset(params: DownloadAsset$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
