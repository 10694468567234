/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getCountsByStatus } from '../fn/statistics/get-counts-by-status';
import { GetCountsByStatus$Params } from '../fn/statistics/get-counts-by-status';
import { getHistory } from '../fn/statistics/get-history';
import { GetHistory$Params } from '../fn/statistics/get-history';
import { HistoryStatisticDto } from '../models/history-statistic-dto';
import { StatusStatisticDto } from '../models/status-statistic-dto';

@Injectable({ providedIn: 'root' })
export class StatisticsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCountsByStatus()` */
  static readonly GetCountsByStatusPath = '/statistics/connection-requests/status';

  /**
   * Get Connection Request Counts By Status.
   *
   * Get the counts of connection requests grouped by module and status Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountsByStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountsByStatus$Response(params?: GetCountsByStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<StatusStatisticDto>> {
    return getCountsByStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Connection Request Counts By Status.
   *
   * Get the counts of connection requests grouped by module and status Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountsByStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountsByStatus(params?: GetCountsByStatus$Params, context?: HttpContext): Observable<StatusStatisticDto> {
    return this.getCountsByStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatusStatisticDto>): StatusStatisticDto => r.body)
    );
  }

  /** Path part for operation `getHistory()` */
  static readonly GetHistoryPath = '/statistics/connection-requests/history';

  /**
   * Get Connection Request History Statistic.
   *
   * Get historical time series with number of connection requests Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistory$Response(params?: GetHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<HistoryStatisticDto>> {
    return getHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Connection Request History Statistic.
   *
   * Get historical time series with number of connection requests Allowed permissions: **custom/connectionRequest/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHistory(params?: GetHistory$Params, context?: HttpContext): Observable<HistoryStatisticDto> {
    return this.getHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<HistoryStatisticDto>): HistoryStatisticDto => r.body)
    );
  }

}
