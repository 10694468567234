/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createConfiguration } from '../fn/customer-configuration/create-configuration';
import { CreateConfiguration$Params } from '../fn/customer-configuration/create-configuration';
import { CustomerConfigurationDto } from '../models/customer-configuration-dto';
import { deleteConfiguration } from '../fn/customer-configuration/delete-configuration';
import { DeleteConfiguration$Params } from '../fn/customer-configuration/delete-configuration';
import { getAll } from '../fn/customer-configuration/get-all';
import { GetAll$Params } from '../fn/customer-configuration/get-all';
import { getConfiguration } from '../fn/customer-configuration/get-configuration';
import { GetConfiguration$Params } from '../fn/customer-configuration/get-configuration';
import { getConfigurationById } from '../fn/customer-configuration/get-configuration-by-id';
import { GetConfigurationById$Params } from '../fn/customer-configuration/get-configuration-by-id';
import { ResponseWrapperListCustomerConfigurationDto } from '../models/response-wrapper-list-customer-configuration-dto';
import { updateConfiguration } from '../fn/customer-configuration/update-configuration';
import { UpdateConfiguration$Params } from '../fn/customer-configuration/update-configuration';

@Injectable({ providedIn: 'root' })
export class CustomerConfigurationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getConfigurationById()` */
  static readonly GetConfigurationByIdPath = '/configurations/{id}';

  /**
   * Get customer configuration.
   *
   * Allowed permissions: **custom/customerConfiguration/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfigurationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigurationById$Response(params: GetConfigurationById$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerConfigurationDto>> {
    return getConfigurationById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customer configuration.
   *
   * Allowed permissions: **custom/customerConfiguration/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfigurationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigurationById(params: GetConfigurationById$Params, context?: HttpContext): Observable<CustomerConfigurationDto> {
    return this.getConfigurationById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerConfigurationDto>): CustomerConfigurationDto => r.body)
    );
  }

  /** Path part for operation `updateConfiguration()` */
  static readonly UpdateConfigurationPath = '/configurations/{id}';

  /**
   * Update customer configuration.
   *
   * Updates a customer configuration containing all the settings needed to configure the environment.
   *         Assets for logo/favicon need to be updated and uploaded separately.
   *          Allowed permissions: **custom/customerConfiguration/update**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateConfiguration$Response(params: UpdateConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerConfigurationDto>> {
    return updateConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Update customer configuration.
   *
   * Updates a customer configuration containing all the settings needed to configure the environment.
   *         Assets for logo/favicon need to be updated and uploaded separately.
   *          Allowed permissions: **custom/customerConfiguration/update**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateConfiguration(params: UpdateConfiguration$Params, context?: HttpContext): Observable<CustomerConfigurationDto> {
    return this.updateConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerConfigurationDto>): CustomerConfigurationDto => r.body)
    );
  }

  /** Path part for operation `deleteConfiguration()` */
  static readonly DeleteConfigurationPath = '/configurations/{id}';

  /**
   * Delete customer configuration.
   *
   * Deletes the customer configuration including related settings and assets. Allowed permissions: **custom/customerConfiguration/delete**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConfiguration$Response(params: DeleteConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete customer configuration.
   *
   * Deletes the customer configuration including related settings and assets. Allowed permissions: **custom/customerConfiguration/delete**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConfiguration(params: DeleteConfiguration$Params, context?: HttpContext): Observable<void> {
    return this.deleteConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAll()` */
  static readonly GetAllPath = '/configurations';

  /**
   * Get customer configurations.
   *
   * Allowed permissions: **custom/customerConfiguration/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: GetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseWrapperListCustomerConfigurationDto>> {
    return getAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customer configurations.
   *
   * Allowed permissions: **custom/customerConfiguration/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: GetAll$Params, context?: HttpContext): Observable<ResponseWrapperListCustomerConfigurationDto> {
    return this.getAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseWrapperListCustomerConfigurationDto>): ResponseWrapperListCustomerConfigurationDto => r.body)
    );
  }

  /** Path part for operation `createConfiguration()` */
  static readonly CreateConfigurationPath = '/configurations';

  /**
   * Create customer configuration.
   *
   * Creates a customer configuration containing all the settings needed to configure the environment.
   *         Assets for logo/favicon need to be uploaded separately.
   *          Allowed permissions: **custom/customerConfiguration/create**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createConfiguration$Response(params: CreateConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerConfigurationDto>> {
    return createConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Create customer configuration.
   *
   * Creates a customer configuration containing all the settings needed to configure the environment.
   *         Assets for logo/favicon need to be uploaded separately.
   *          Allowed permissions: **custom/customerConfiguration/create**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createConfiguration(params: CreateConfiguration$Params, context?: HttpContext): Observable<CustomerConfigurationDto> {
    return this.createConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerConfigurationDto>): CustomerConfigurationDto => r.body)
    );
  }

  /** Path part for operation `getConfiguration()` */
  static readonly GetConfigurationPath = '/configurations/self';

  /**
   * Get own customer configuration.
   *
   * Allowed permissions: **custom/customerConfiguration/read**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfiguration$Response(params?: GetConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerConfigurationDto>> {
    return getConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Get own customer configuration.
   *
   * Allowed permissions: **custom/customerConfiguration/read**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfiguration(params?: GetConfiguration$Params, context?: HttpContext): Observable<CustomerConfigurationDto> {
    return this.getConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerConfigurationDto>): CustomerConfigurationDto => r.body)
    );
  }

}
