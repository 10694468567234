/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { checkResponsibility } from '../fn/responsibility-check/check-responsibility';
import { CheckResponsibility$Params } from '../fn/responsibility-check/check-responsibility';
import { ResponsibilityCheckDto } from '../models/responsibility-check-dto';

@Injectable({ providedIn: 'root' })
export class ResponsibilityCheckService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `checkResponsibility()` */
  static readonly CheckResponsibilityPath = '/responsibility-check';

  /**
   * Check if customer is responsible for a given address.
   *
   * Allowed permissions: **No permission required**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkResponsibility()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkResponsibility$Response(params: CheckResponsibility$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponsibilityCheckDto>> {
    return checkResponsibility(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if customer is responsible for a given address.
   *
   * Allowed permissions: **No permission required**
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkResponsibility$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkResponsibility(params: CheckResponsibility$Params, context?: HttpContext): Observable<ResponsibilityCheckDto> {
    return this.checkResponsibility$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponsibilityCheckDto>): ResponsibilityCheckDto => r.body)
    );
  }

}
