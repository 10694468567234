/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PagedResponseConnectionRequestDto } from '../../models/paged-response-connection-request-dto';

export interface GetConnectionRequests$Params {

/**
 * Filter by module(s)
 */
  module?: Array<'ELECTRICITY' | 'GAS' | 'WATER' | 'HEAT'>;

/**
 * Filter by flowType(s)
 */
  flowType?: Array<'PV' | 'STORAGE' | 'BALCONY_PV' | 'GRID_CONNECTION' | 'CHARGING_DEVICE' | 'HEAT_PUMP' | 'OTHER' | 'GAS' | 'WATER' | 'SEWAGE' | 'CONSTRUCTION_WATER' | 'HEAT_NEW' | 'HEAT_CHANGE' | 'HEAT_DETACH' | 'MAIN_POWER_SUPPLY' | 'CUSTOMER_FACILITY'>;

/**
 * Filter by current status
 */
  'currentStatus.status'?: Array<'NEW' | 'PROGRESS' | 'PENDING' | 'REJECTED' | 'DONE'>;

/**
 * Search by: requestId, location (any field), requesterContact (firstName, lastName).<br/>
 *                  The search works case-insensitive and uses trigram similarity to find matches for most of the fields.<br/>
 *                  For specific fields a case-insensitive contains-match is used (requestId, location.address.zipCode)
 */
  q?: string;

/**
 * Zero-based page index (0..N)
 */
  page?: number;

/**
 * The size of the page to be returned
 */
  size?: number;

/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. Sortable fields: createdAt, requesterContact, location.
 */
  sort?: Array<string>;
}

export function getConnectionRequests(http: HttpClient, rootUrl: string, params?: GetConnectionRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<PagedResponseConnectionRequestDto>> {
  const rb = new RequestBuilder(rootUrl, getConnectionRequests.PATH, 'get');
  if (params) {
    rb.query('module', params.module, {});
    rb.query('flowType', params.flowType, {});
    rb.query('currentStatus.status', params['currentStatus.status'], {});
    rb.query('q', params.q, {});
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.query('sort', params.sort, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PagedResponseConnectionRequestDto>;
    })
  );
}

getConnectionRequests.PATH = '/connection-requests';
