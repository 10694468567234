/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface DownloadAsset$Params {

/**
 * Asset type
 */
  assetType: 'logo' | 'favicon';

/**
 * Id of the customer configuration
 */
  customerConfigurationId: string;
}

export function downloadAsset(http: HttpClient, rootUrl: string, params: DownloadAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
  const rb = new RequestBuilder(rootUrl, downloadAsset.PATH, 'get');
  if (params) {
    rb.path('assetType', params.assetType, {});
    rb.path('customerConfigurationId', params.customerConfigurationId, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Blob>;
    })
  );
}

downloadAsset.PATH = '/assets/{assetType}/{customerConfigurationId}/download';
